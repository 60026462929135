import React from "react";
import './AboutMe.css';

const AboutMe = () => {

 return (
    <div className="container">
       <div className="about">
      
         <p className="about">  Hi, I'm Yovana – a go-getter and problem-solver with a love for the outdoors. My passion for helping others and overcoming challenges drives me to make a difference every day.
      </p>

  </div>
    </div>

 );
};

export default AboutMe;