import Pictures from "./Pictures";

function Home() {


    return ( 


        <div className="aboutMe">
        
     <Pictures/>
        </div>
);
    }
export default Home;